
import { QFile } from "quasar";
import { Component, Mixins, Ref } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

interface historyData {
  id: string;
  message: string;
  files: any;
}

@Component
export default class FormEncuestaServicio extends Mixins(FormValidator) {
  @Ref("file_input") file_input!: QFile;

  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;
  private token: any;
  ticket_info : any = {}

  success_dialog = false;

  protected formHistory: historyData = {
    id: "",
    message: "",
    files: null
  };

  toolbal = [
    [
      {
        icon: this.$q.iconSet.editor.align,
        fixedLabel: true,
        list: "only-icons",
        options: ["left", "center", "right", "justify"]
      }
    ],
    [
      {
        icon: this.$q.iconSet.editor.bold,
        options: ["bold", "italic", "strike", "underline"]
      }
    ],
    ["link"],
    [
      {
        label: "Fuente",
        icon: this.$q.iconSet.editor.font,
        list: "no-icons",
        options: ["Principal"]
      }
    ],
    [
      {
        label: this.$q.lang.editor.fontSize,
        icon: this.$q.iconSet.editor.fontSize,
        fixedLabel: true,
        fixedIcon: true,
        list: "no-icons",
        options: ["size-1", "size-2", "size-3", "size-4", "size-5"]
      }
    ]
  ];

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  private htmlToString(html: string) {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  private subirArchivo() {
    this.file_input.pickFiles();
  }

  private name(filename: any) {
    let name = filename.substr(0, filename.lastIndexOf(".")) || filename;
    name = name.replaceAll("_", " ");
    return name;
  }

  private extension(filename: any) {
    var extension = filename.split(".").pop();
    return extension;
  }


  private clearFile(index: number) {
    this.formHistory.files.splice(index, 1);
  }

  private validateImage(files: any) {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size) {
          if (Number(files[i].size) > 51 * 1024 * 1024) {
            return "El tamaño del documento no debe ser mayor a 50 Mb";
          }
        }
      }
    }
    return "";
  }

  private updateFiles(files: any) {
    if (files) {
      const validate = this.validateImage(files);
      if (validate != "") return this.notify(validate);
    }
    if (Array.isArray(files) === false || files.length === 0) {
      this.formHistory.files = null;
    } else if (Array.isArray(this.formHistory.files) === true) {
      const diff = this.formHistory.files.filter(
        (file: any) => files.indexOf(file) === -1
      );

      if (diff.length === 1 && this.formHistory.files.length > 1) {
        this.formHistory.files = files.slice();
      } else {
        this.formHistory.files = diff.concat(files);
      }
    } else {
      this.formHistory.files = files.slice();
    }
  }

  private checkCharCount(): boolean {
    let length = this.htmlToString(this.formHistory.message).length;
    if (length > 4000) {
      return false;
    }
    return true;
  }

  private clearFormHistory() {
    this.formHistory.id = "";
    this.formHistory.message = "";
    this.formHistory.files = null;
  }

  private onSubmit() {
    if (
      this.formHistory.message.trim() == "" ||
      this.formHistory.message == "&nbsp;"
    ) {
      return this.notify("Debe ingresar un mensaje");
    }
    if (this.checkCharCount() === false) {
      return this.notify("El texto no puede exceder los 900 carácteres");
    }
    this.$q.loading.show();

    let adjuntos_input = document.getElementsByName("Files");
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") adjuntos_empty = false;
    }
    if (adjuntos_empty) adjuntos = null;
    const formHistory = new FormData();
    if (this.formHistory.files) {
      this.formHistory.files.forEach((item: any, index: any) =>
        formHistory.append("my-file-" + index, item)
      );
    }
    this.$axios
      .post("ticket-historial-respuestas/correo/responder", formHistory, {
        params: {
          mensaje: this.formHistory.message,
          token: this.token
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        //this.notify("Respuesta enviada correctamente");
        this.clearFormHistory();
        this.success_dialog = true;
        setTimeout(() => window.open(this.url_frontoffice, "_self"), 5000);
      })
      .catch(error => {
        let message = "Ha ocurrido un error";
        switch (error.response.data.error.message) {
          case "respuesta_no_data":
            message = "La respuesta no está habilitada";
            break;
          case "empty_mensaje":
            message = "Debe completar el campo mensaje";
            break;
          case "upload_error":
            message =
              "Hubo un error subiendo los archivos, vuelva a intentarlo";
            break;
          case "invalid_ticket":
            message = "No es posible responder. La solicitud fue cerrada";
            break;
          case "expired_token":
            message = "Esta solicitud de respuesta ya ha sido respondida";
            break;
        }
        this.notify(message);
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private getInfo() {
    this.$axios
      .get("ticket-historial-respuestas/info", {
        params: {
          token: this.token
        }
      })
      .then(response => {
        this.ticket_info = response.data
      })
      .catch(error => {
      })
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.token
    ) {
      this.token = this.$router.currentRoute.params.token;
      this.getInfo();
    } else {
      window.open(this.url_frontoffice, "_self");
    }
  }
}
